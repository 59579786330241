import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AgreementContent from "./AgreementContent";

function AgreementSign(props) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [isShowAgreeTerms, setIsShowAgreeTerms] = useState(false);

  return (
    <div
      className="flex flex-col justify-between md:pr-4  mx-2 mb-2 pt-1  md:flex-row"
      data-tut="IsAgree"
    >
      <div className="flex flex-row items-center">
        <input
          className="mr-3 op-checkbox op-checkbox-m"
          type="checkbox"
          value={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            if (e.target.checked) {
              props.setIsAgreeTour(false);
            }
          }}
        />
        <div>
          <span>{t("agree-p1")}</span>
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => {
              setIsShowAgreeTerms(true);
              props.setIsAgreeTour(false);
            }}
          >
            {t("agree-p2")}
          </span>
          <span> {t("agree-p3")}</span>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => {
            if (isChecked) {
              props.setIsAgreeTour(false);
              props.setIsAgree(true);
            } else {
              props.setIsAgreeTour(true);
            }
          }}
          className="op-btn op-btn-primary op-btn-sm w-full md:w-auto"
        >
          {t("agrre-button")}
        </button>
      </div>
      {isShowAgreeTerms && (
        <AgreementContent
          setIsAgreeTour={props.setIsAgreeTour}
          setIsAgree={props.setIsAgree}
          setIsShowAgreeTerms={setIsShowAgreeTerms}
        />
      )}
    </div>
  );
}

export default AgreementSign;
